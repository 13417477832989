import moment from "moment";

require('./bootstrap');

window.Vue = require('vue').default;

import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import router from './router/router'

import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VueMask from 'v-mask'
import store from './store/store'
import Vue from "vue";
import money from 'vuejs-money';
import VueCompositionAPI from '@vue/composition-api'

import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

Vue.use(Vuetify)
Vue.use(VueMask)
Vue.use(VueCompositionAPI)
Vue.use(money, {
    decimal: ',',
    thousands: '.',
    prefix: '',
    suffix: '',
    precision: 0,
    masked: false
})

const opts = {
    icons: {
        //iconfont: 'mdi'
        iconfont: 'md' || 'fa',
    },
    theme: {
        themes: {
            light: {
                primary: '#1f2658',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        },
    },
}

Vue.component('template-app', require('./App.vue').default);
Vue.component('infobox', require('@components/InfoBox').default);


Vue.prototype.$maskCpf = "###.###.###-##"
Vue.prototype.$maskCnpj = "##.###.###/####-##"
Vue.prototype.$maskData = "##/##/####"
Vue.prototype.$maskCep = "#####-###"
Vue.prototype.$maskTelefone = "(##) #####-####"

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});
Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY - HH:mm')
    }
});
Vue.filter('tipoMovimentoEstoque', function(value) {
    switch (value) {
        case 1:
            return 'Entrada';    
        case 2:
            return 'Saída';    
        case 3:
            return 'Entrada/Transferência';    
        case 4:
            return 'Saída/Transferência';    
        default:
            return 'Tipo Inválido';
    }
});

Vue.filter('formatMoney', function(value, symbol = 'R$', digits = 2) {
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });

    if(symbol !== '')symbol = symbol+' ';

    return symbol + formatter.format(value);
});

Vue.prototype.$getFormData = function(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

Vue.prototype.$isBirthdayToday = function(dateString) {
    let today = new Date();
    let parts = dateString.split("-");
    let month = parseInt(parts[1], 10) - 1;
    let day = parseInt(parts[2], 10);
    let dateObject = new Date(today.getFullYear(), month, day);
            
    return today.getMonth() === dateObject.getMonth() && today.getDate() === dateObject.getDate();
}

const app = new Vue({
    el: '#app',
    router,
    store,
    vuetify: new Vuetify(opts)
});
