import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'


Vue.use(VueRouter)

const routes = [{
        path: "/",
        meta: {
            requiresAuth: true
        },
        component: () => import('@templates/default/Index'),
        children: [{
                path: '',
                name: 'Home',
                component: () => import('@pages/Home/Home')
            },
            {
                path: 'cliente',
                name: 'Cliente',
                meta: {
                    title: "Clientes"
                },
                component: () => import('@pages/Cliente/Cliente')
            },
            {
                path: 'cliente/create',
                name: 'ClienteCreate',
                props: true,
                meta: {
                    title: "Cadastro de Cliente"
                },
                component: () => import('@pages/Cliente/ClienteCreate')
            },
            {
                path: 'cliente/edit/:id',
                props: true,
                name: 'ClienteEdit',
                meta: {
                    title: "Edição de Cliente"
                },
                component: () => import('@pages/Cliente/ClienteEdit')
            },
            {
                path: 'cliente/show/:id',
                props: true,
                name: 'ClienteShow',
                meta: {
                    title: "Visualizar Cliente"
                },
                component: () => import('@pages/Cliente/ClienteShow')
            },
            {
                path: 'funcionario',
                name: 'Funcionario',
                meta: {
                    title: "Funcionários"
                },
                component: () => import('@pages/Funcionario/Funcionario')
            },
            {
                path: 'funcionario/create',
                name: 'FuncionarioCreate',
                meta: {
                    title: "Cadastro de Funcionário"
                },
                component: () => import('@pages/Funcionario/FuncionarioCreate')
            },
            {
                path: 'funcionario/edit/:id',
                props: true,
                name: 'FuncionarioEdit',
                meta: {
                    title: "Edição de Funcionário"
                },
                component: () => import('@pages/Funcionario/FuncionarioEdit')
            },
            {
                path: 'funcionario/show/:id',
                props: true,
                name: 'FuncionarioShow',
                meta: {
                    title: "Visualizar Funcionário"
                },
                component: () => import('@pages/Funcionario/FuncionarioShow')
            },
            {
                path: 'funcionario/show/acesso/:id',
                props: true,
                name: 'FuncionarioAcessoEdit',
                meta: {
                    title: "Editar Acesso"
                },
                component: () => import('@pages/Funcionario/AcessoEdit')
            },
            {
                path: 'listarprodutos',
                name: 'ListarProdutosXml',
                meta: {
                    title: "Listar Produtos"
                },
                component: () => import('@pages/MovimentoEstoque/ListarProdutosXml')
            },
            {
                path: 'pedidofabrica',
                name: 'PedidoFabricaXml',
                meta: {
                    title: "Listar Produtos de Orçamento"
                },
                component: () => import('@pages/MovimentoEstoque/PedidoFabricaXml')
            },
            {
                path: 'empresa',
                name: 'Empresa',
                meta: {
                    title: "Empresas"
                },
                component: () => import('@pages/Empresa/Empresa')
            },
            {
                path: 'empresa/create',
                name: 'EmpresaCreate',
                meta: {
                    title: "Cadastro de Empresa"
                },
                component: () => import('@pages/Empresa/EmpresaCreate')
            },
            {
                path: 'empresa/edit/:id',
                props: true,
                name: 'EmpresaEdit',
                meta: {
                    title: "Edição de Empresa"
                },
                component: () => import('@pages/Empresa/EmpresaEdit')
            },
            {
                path: 'empresa/show/:id',
                props: true,
                name: 'EmpresaShow',
                meta: {
                    title: "Visualizar Empresa"
                },
                component: () => import('@pages/Empresa/EmpresaShow')
            },

            {
                path: 'produto',
                name: 'Produto',
                meta: {
                    title: "Produtos"
                },
                component: () => import('@pages/Produto/Produto')
            },
            {
                path: 'produto/create',
                name: 'ProdutoCreate',
                meta: {
                    title: "Cadastro de Produto"
                },
                component: () => import('@pages/Produto/ProdutoCreate')
            },
            {
                path: 'produto/edit/:id',
                props: true,
                name: 'ProdutoEdit',
                meta: {
                    title: "Edição de Produto"
                },
                component: () => import('@pages/Produto/ProdutoEdit')
            },
            {
                path: 'produto/estoque/:id',
                props: true,
                name: 'ProdutoEstoque',
                meta: {
                    title: "Estoque"
                },
                component: () => import('@pages/Produto/ProdutoEstoque')
            },

            {
                path: 'fornecedor',
                name: 'Fornecedor',
                meta: {
                    title: "Fornecedores"
                },
                component: () => import('@pages/Fornecedor/Fornecedor')
            },
            {
                path: 'fornecedor/create',
                name: 'FornecedorCreate',
                meta: {
                    title: "Cadastro de Fornecedor"
                },
                component: () => import('@pages/Fornecedor/FornecedorCreate')
            },
            {
                path: 'fornecedor/edit/:id',
                props: true,
                name: 'FornecedorEdit',
                meta: {
                    title: "Edição de Fornecedor"
                },
                component: () => import('@pages/Fornecedor/FornecedorEdit')
            },
            {
                path: 'fornecedor/show/:id',
                props: true,
                name: 'FornecedorShow',
                meta: {
                    title: "Visualizar Fornecedor"
                },
                component: () => import('@pages/Fornecedor/FornecedorShow')
            },

            {
                path: 'terceirizado',
                name: 'Terceirizado',
                meta: {
                    title: "Fornecedores"
                },
                component: () => import('@pages/Terceirizado/Terceirizado')
            },
            {
                path: 'terceirizado/create',
                props: true,
                name: 'TerceirizadoCreate',
                meta: {
                    title: "Cadastro de Terceirizado"
                },
                component: () => import('@pages/Terceirizado/TerceirizadoCreate')
            },
            {
                path: 'terceirizado/edit/:id',
                props: true,
                name: 'TerceirizadoEdit',
                meta: {
                    title: "Edição de Terceirizado"
                },
                component: () => import('@pages/Terceirizado/TerceirizadoEdit')
            },
            {
                path: 'terceirizado/show/:id',
                props: true,
                name: 'TerceirizadoShow',
                meta: {
                    title: "Visualizar Terceirizado"
                },
                component: () => import('@pages/Terceirizado/TerceirizadoShow')
            },

            {
                path: 'profissional',
                name: 'Profissional',
                meta: {
                    title: "Profissionais"
                },
                component: () => import('@pages/Profissional/Profissional')
            },
            {
                path: 'profissional/create',
                name: 'ProfissionalCreate',
                props: true,
                meta: {
                    title: "Cadastro de Profissional"
                },
                component: () => import('@pages/Profissional/ProfissionalCreate')
            },
            {
                path: 'profissional/edit/:id',
                props: true,
                name: 'ProfissionalEdit',
                meta: {
                    title: "Edição de Profissional"
                },
                component: () => import('@pages/Profissional/ProfissionalEdit')
            },
            {
                path: 'profissional/show/:id',
                props: true,
                name: 'ProfissionalShow',
                meta: {
                    title: "Visualizar Profissional"
                },
                component: () => import('@pages/Profissional/ProfissionalShow')
            },

            {
                path: 'categoria',
                name: 'Categoria',
                meta: {
                    title: "Categorias"
                },
                component: () => import('@pages/Categoria/Categoria')
            },
            {
                path: 'categoria/create',
                name: 'CategoriaCreate',
                meta: {
                    title: "Cadastro de Categoria"
                },
                component: () => import('@pages/Categoria/CategoriaCreate')
            },
            {
                path: 'categoria/edit/:id',
                props: true,
                name: 'CategoriaEdit',
                meta: {
                    title: "Edição de Categoria"
                },
                component: () => import('@pages/Categoria/CategoriaEdit')
            },
            {
                path: 'folha-rosto',
                name: 'FolhaRosto',
                meta: {
                    title: "Folhas de Rosto"
                },
                component: () => import('@pages/FolhaRosto/FolhaRosto')
            },
            {
                path: 'folha-rosto/create',
                name: 'FolhaRostoCreate',
                meta: {
                    title: "Cadastro de Folhas de Rosto"
                },
                component: () => import('@pages/FolhaRosto/FolhaRostoCreate')
            },
            {
                path: 'folha-rosto/edit/:id',
                props: true,
                name: 'FolhaRostoEdit',
                meta: {
                    title: "Edição de Folhas de Rosto"
                },
                component: () => import('@pages/FolhaRosto/FolhaRostoEdit')
            },
            {
                path: 'orcamento',
                name: 'Orcamento',
                meta: {
                    title: "Orçamentos"
                },
                component: () => import('@pages/Orcamento/Orcamento')
            },
            {
                path: 'orcamento/create',
                name: 'OrcamentoCreate',
                props: true,
                meta: {
                    title: "Cadastro de Orçamento"
                },
                component: () => import('@pages/Orcamento/OrcamentoCreate')
            },
            {
                path: 'orcamento/edit/:id',
                name: 'OrcamentoEdit',
                props: true,
                meta: {
                    title: "Editar de Orçamento"
                },
                component: () => import('@pages/Orcamento/OrcamentoEdit')
            },
            {
                path: 'orcamento/cliente/edit/:id',
                name: 'OrcamentoDadosEdit',
                props: true,
                meta: {
                    title: "Editar de Orçamento"
                },
                component: () => import('@pages/Orcamento/OrcamentoDadosEdit')
            },
            {
                path: 'orcamento/mensagem/:id',
                name: 'OrcamentoMensagem',
                props: true,
                meta: {
                    title: "Mensagens de Orçamento"
                },
                component: () => import('@pages/Orcamento/OrcamentoMensagem')
            },
            {
                path: 'pedido',
                name: 'Pedido',
                meta: {
                    title: "Pedidos"
                },
                component: () => import('@pages/Pedido/Pedido')
            },
            {
                path: 'pedido/show/:id',
                props:true,
                name: 'PedidoShow',
                meta: {
                    title: "Visualizar Pedido"
                },
                component: () => import('@pages/Pedido/PedidoShow')
            },
            {
                path: 'pedido/confirm/:id',
                props:true,
                name: 'ConfirmarPedido',
                meta: {
                    title: "Confirmar Pedido"
                },
                component: () => import('@pages/Pedido/ConfirmarPedido')
            },
            {
                path: 'deposito',
                name: 'Deposito',
                meta: {
                    title: "Depósito - Pedidos"
                },
                component: () => import('@pages/Deposito/Pedido')
            },
            {
                path: 'deposito/:id',
                props:true,
                name: 'Agendar',
                meta: {
                    title: "Depósito - Pedidos"
                },
                component: () => import('@pages/Deposito/Agendar')
            },
            {
                path: 'separacao',
                name: 'Separacao',
                meta: {
                    title: "Separação"
                },
                component: () => import('@pages/Deposito/Separacao')
            },
            {
                path: 'entrega',
                name: 'AgendaEntrega',
                meta: {
                    title: "Agenda de Entregas"
                },
                component: () => import('@pages/AgendaEntrega/AgendaEntrega')
            },
            {
                path: 'entrega/show/:id',
                props:true,
                name: 'AgendaEntregaShow',
                meta: {
                    title: "Visualizar Agenda de Entregas"
                },
                component: () => import('@pages/AgendaEntrega/AgendaEntregaShow')
            },
            {
                path: 'estoque',
                name: 'MovimentoEstoque',
                meta: {
                    title: "Movimentação de Estoque"
                },
                component: () => import('@pages/MovimentoEstoque/MovimentoEstoque')
            },
            {
                path: 'estoque/show/:id',
                props:true,
                name: 'MovimentoEstoqueShow',
                meta: {
                    title: "Visualizar Movimentação de Estoque"
                },
                component: () => import('@pages/MovimentoEstoque/MovimentoEstoqueShow')
            },
            {
                path: 'estoque/entrada',
                name: 'EstoqueEntrada',
                meta: {
                    title: "Entrada de Estoque"
                },
                component: () => import('@pages/MovimentoEstoque/EstoqueEntrada')
            },
            {
                path: 'estoque/transferencia',
                name: 'EstoqueTransferencia',
                meta: {
                    title: "Transferência de Estoque"
                },
                component: () => import('@pages/MovimentoEstoque/EstoqueTransferencia')
            },
            {
                path: 'assistencia',
                name: 'Assistencia',
                meta: {
                    title: "Assistência Técnica"
                },
                component: () => import('@pages/Assistencia/Assistencia')
            },
            {
                path: 'assistencia/create',
                name: 'AssistenciaCreate',
                meta: {
                    title: "Nova Assistência Técnica"
                },
                component: () => import('@pages/Assistencia/AssistenciaCreate')
            },
            {
                path: 'assistencia/show/:id',
                props:true,
                name: 'AssistenciaShow',
                meta: {
                    title: "Visualizar Assistência Técnica"
                },
                component: () => import('@pages/Assistencia/AssistenciaShow')
            },
            {
                path: 'servico',
                name: 'Servico',
                meta: {
                    title: "Serviços Terceirizados"
                },
                component: () => import('@pages/Servico/Servico')
            },
            {
                path: 'servico/create',
                props: true,
                name: 'ServicoCreate',
                meta: {
                    title: "Novo Serviço Terceirizado"
                },
                component: () => import('@pages/Servico/ServicoCreate')
            },
            {
                path: 'servico/show/:id',
                props:true,
                name: 'ServicoShow',
                meta: {
                    title: "Visualizar Serviço Terceirizado"
                },
                component: () => import('@pages/Servico/ServicoShow')
            },
            {
                path: 'localestoque',
                name: 'LocalEstoque',
                meta: {
                    title: "Local de Estoque"
                },
                component: () => import('@pages/LocalEstoque/LocalEstoque')
            },
            {
                path: 'localestoque/create',
                name: 'LocalEstoqueCreate',
                meta: {
                    title: "Novo Local de Estoque"
                },
                component: () => import('@pages/LocalEstoque/LocalEstoqueCreate')
            },
            {
                path: 'localestoque/edit/:id',
                name: 'LocalEstoqueEdit',
                meta: {
                    title: "Editar Local de Estoque"
                },
                props:true,
                component: () => import('@pages/LocalEstoque/LocalEstoqueEdit')
            },
            {
                path: 'veiculo',
                name: 'Veiculo',
                meta: {
                    title: "Veículos"
                },
                component: () => import('@pages/Veiculo/Veiculo')
            },
            {
                path: 'veiculo/create',
                name: 'VeiculoCreate',
                meta: {
                    title: "Novo Veículo"
                },
                component: () => import('@pages/Veiculo/VeiculoCreate')
            },
            {
                path: 'veiculo/edit/:id',
                name: 'VeiculoEdit',
                props:true,
                meta: {
                    title: "Editar Veículo"
                },
                component: () => import('@pages/Veiculo/VeiculoEdit')
            },
            {
                path: 'manutencao/create/:id',
                name: 'ManutencaoCreate',
                props:true,
                meta: {
                    title: "Cadastrar Manutenção"
                },
                component: () => import('@pages/Veiculo/ManutencaoCreate')
            },
            {
                path: 'manutencao/show/:id',
                name: 'ManutencaoShow',
                props:true,
                meta: {
                    title: "Visualizar Manutenção"
                },
                component: () => import('@pages/Veiculo/ManutencaoShow')
            },
            {
                path: 'veiculo/show/:id',
                name: 'VeiculoShow',
                props:true,
                meta: {
                    title: "Visualizar Veículo"
                },
                component: () => import('@pages/Veiculo/VeiculoShow')
            },
            {
                path: 'ferramenta',
                name: 'Ferramenta',
                meta: {
                    title: "Controle de Ferramentas"
                },
                component: () => import('@pages/Ferramenta/Ferramenta')
            },
            {
                path: 'ferramenta/create',
                name: 'FerramentaCreate',
                meta: {
                    title: "Nova Ferramenta"
                },
                component: () => import('@pages/Ferramenta/FerramentaCreate')
            },
            {
                path: 'ferramenta/edit/:id',
                props: true,
                name: 'FerramentaEdit',
                meta: {
                    title: "Editar Ferramenta"
                },
                component: () => import('@pages/Ferramenta/FerramentaEdit')
            },
            {
                path: 'ferramenta/show/:id',
                props: true,
                name: 'FerramentaShow',
                meta: {
                    title: "Visualizar Ferramenta"
                },
                component: () => import('@pages/Ferramenta/FerramentaShow')
            },
            {
                path: 'relatorio/produto',
                name: 'RelatorioProduto',
                meta: {
                    title: "Relatório de Produto"
                },
                component: () => import('@pages/Relatorio/Produto')
            },
            {
                path: 'relatorio/separacao',
                name: 'RelatorioSeparacao',
                meta: {
                    title: "Relatório de Separação de Caixa"
                },
                component: () => import('@pages/Relatorio/RelatorioSeparacao')
            },
            {
                path: 'relatorio/movimentacao-estoque',
                name: 'RelatorioMovimentacaoEstoque',
                meta: {
                    title: "Movimentação"
                },
                component: () => import('@pages/Relatorio/MovimentacaoEstoque')
            },
            {
                path: 'relatorio/movimentacao-produto',
                name: 'RelatorioMovimentacaoProduto',
                meta: {
                    title: "Movimentação de Produto"
                },
                component: () => import('@pages/Relatorio/MovimentacaoProduto')
            },
            {
                path: 'relatorio/orcamento',
                name: 'RelatorioOrcamento',
                meta: {
                    title: "Relatório de Orçamento"
                },
                component: () => import('@pages/Relatorio/Orcamento')
            },
            {
                path: 'relatorio/pedido',
                name: 'RelatorioPedido',
                meta: {
                    title: "Relatório de Pedido"
                },
                component: () => import('@pages/Relatorio/Pedido')
            },
            {
                path: 'relatorio/autorizacao-pedido',
                name: 'RelatorioAuthPedido',
                meta: {
                    title: "Autorização de Pedido"
                },
                component: () => import('@pages/Relatorio/AuthPedido')
            },
            {
                path: 'relatorio/entrega',
                name: 'RelatorioEntrega',
                meta: {
                    title: "Relatório de Entrega"
                },
                component: () => import('@pages/Relatorio/Entrega')
            },
            {
                path: 'relatorio/assistencia',
                name: 'RelatorioAssistencia',
                meta: {
                    title: "Relatório de Assistência Técnica"
                },
                component: () => import('@pages/Relatorio/Assistencia')
            },
            {
                path: 'relatorio/veiculo',
                name: 'RelatorioVeiculo',
                meta: {
                    title: "Relatório de Veículo"
                },
                component: () => import('@pages/Relatorio/Veiculo')
            },
            {
                path: 'relatorio/terceirizado',
                name: 'RelatorioTerceirizado',
                meta: {
                    title: "Relatório de Terceirizados"
                },
                component: () => import('@pages/Relatorio/Terceirizado')
            },
            {
                path: 'relatorio/aniversario',
                name: 'RelatorioAniversario',
                meta: {
                    title: "Relatório de Aniversário"
                },
                component: () => import('@pages/Relatorio/Aniversario')
            },
            {
                path: 'relatorio/estoque',
                name: 'RelatorioEstoque',
                meta: {
                    title: "Relatório de Estoque"
                },
                component: () => import('@pages/Relatorio/Estoque')
            },
            {
                path: 'administrativo/aniversario',
                name: 'AdministrativoAniversario',
                meta: {
                    title: "Mensagens de Aniversário"
                },
                component: () => import('@pages/Administrativo/Aniversario')
            },
            {
                path: 'administrativo/aniversario/show/:id',
                props: true,
                name: 'AniversarioShow',
                meta: {
                    title: "Mensagens de Aniversário"
                },
                component: () => import('@pages/Administrativo/AniversarioShow')
            },
            {
                path: 'administrativo/aniversario/edit/:id',
                props: true,
                name: 'AniversarioEdit',
                meta: {
                    title: "Edição de Mensagens de Aniversário"
                },
                component: () => import('@pages/Administrativo/AniversarioEdit')
            },
            {
                path: 'administrativo/orcamento',
                name: 'AdministrativoOrcamento',
                meta: {
                    title: "Mensagens de Orçamentos"
                },
                component: () => import('@pages/Administrativo/Orcamento')
            },
            {
                path: 'administrativo/mensagem',
                name: 'AdministrativoMensagem',
                meta: {
                    title: "Mensagens Diretas"
                },
                component: () => import('@pages/Administrativo/Mensagem')
            },
            {
                path: 'administrativo/mensagem/create',
                name: 'MensagemCreate',
                meta: {
                    title: "Enviar Mensagen Direta"
                },
                component: () => import('@pages/Administrativo/MensagemCreate')
            },
            {
                path: 'perfil',
                name: 'Perfil',
                meta: {
                    title: "Perfil de Acesso"
                },
                component: () => import('@pages/Perfil/Perfil')
            },
            {
                path: 'perfil/create',
                name: 'PerfilCreate',
                meta: {
                    title: "Cadastro de Perfil"
                },
                component: () => import('@pages/Perfil/PerfilCreate')
            },
            {
                path: 'perfil/edit/:id',
                props: true,
                name: 'PerfilEdit',
                meta: {
                    title: "Edição de Perfil"
                },
                component: () => import('@pages/Perfil/PerfilEdit')
            },
            {
                path: 'permissao/:id',
                props: true,
                name: 'Permissao',
                meta: {
                    title: "Permissões de Acesso"
                },
                component: () => import('@pages/Perfil/Permissao')
            },
            {
                path: 'administrativo/logs',
                name: 'AdministrativoLogs',
                meta: {
                    title: "Logs"
                },
                component: () => import('@pages/Administrativo/Logs')
            },
            {
                path: 'administrativo/rotas',
                name: 'AdministrativoRotas',
                meta: {
                    title: "Rotas"
                },
                component: () => import('@pages/Administrativo/Rotas')
            },
            {
                path: 'perfil-user',
                name: 'PerfilUser',
                meta: {
                    title: "Perfil - Meu Cadastro"
                },
                component: () => import('@pages/PerfilUser/PerfilUser')
            },
            {
                path: 'user/senha-edit/:id',
                props: true,
                name: 'UserSenhaEdit',
                meta: {
                    title: "Perfil - Editar Senha"
                },
                component: () => import('@pages/PerfilUser/UserSenhaEdit')
            },
            {
                path: 'crm',
                props: true,
                name: 'CRMIndex',
                meta: {
                    title: "CRM"
                },
                component: () => import('@pages/CRM/Index')
            },

        ]
    },

    {
        path: "/orcamento/print/:id",
        props: true,
        name: "OrcamentoPrint",
        meta: {
            title: "Imprimir Orçamento"
        },
        component: () => import('@pages/Orcamento/OrcamentoPrint'),
    },

    {
        path: "/pedido/print/:id",
        props: true,
        name: "PedidoPrint",
        meta: {
            title: "Imprimir Pedido"
        },
        component: () => import('@pages/Pedido/PedidoPrint'),
    },

    
    {
        path: "/entrega/relatorio/print/:id",
        props: true,
        name: "RelatorioPrint",
        meta: {
            title: "Imprimir Entrega"
        },
        component: () => import('@pages/AgendaEntrega/RelatorioPrint'),
    },
    {
        path: "/manutencao/print/:id",
        props: true,
        name: "ManutencaoVeiculoPrint",
        meta: {
            title: "Imprimir Comprovante de Manutenção"
        },
        component: () => import('@pages/Veiculo/ManutencaoVeiculoPrint'),
    },
    {
        path: "/entrega/print/:id",
        props: true,
        name: "AgendaEntregaPrint",
        meta: {
            title: "Imprimir Entrega"
        },
        component: () => import('@pages/AgendaEntrega/AgendaEntregaPrint'),
    },

    {
        path: "/assistencia/conclusao/print/:id",
        props: true,
        name: "AssistenciaConclusaoPrint",
        meta: {
            title: "Imprimir Relatório de Conclusão"
        },
        component: () => import('@pages/Assistencia/AssistenciaConclusaoPrint'),
    },
    {
        path: "/assistencia/relatorio/print/:id",
        props: true,
        name: "AssistenciaRelatorioPrint",
        meta: {
            title: "Imprimir Relatório de Assistência"
        },
        component: () => import('@pages/Assistencia/AssistenciaRelatorioPrint'),
    },

    {
        path: "/relatorio/entrega/print",
        name: "EntregaRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Entrega'),
    },
    {
        path: "/relatorio/separacao/print",
        name: "SeparacaoRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Separacao'),
    },
    {
        path: "/relatorio/pedido/print",
        name: "PedidoRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Pedido'),
    },
    {
        path: "/relatorio/aniversario/print",
        name: "AniversarioRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Aniversario'),
    },
    {
        path: "/relatorio/estoque/print",
        name: "EstoqueRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Estoque'),
    },
    {
        path: "/relatorio/orcamento/print",
        name: "OrcamentoRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Orcamento'),
    },
    {
        path: "/relatorio/produto/print",
        name: "ProdutoPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Produto'),
    },
    {
        path: "/relatorio/movimentacaoestoque/print",
        name: "MovimentacaoEstoqueRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/MovimentacaoEstoque'),
    },
    {
        path: "/relatorio/servico/print",
        name: "ServicoPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Servico'),
    },
    {
        path: "/relatorio/assistencia/print",
        name: "AssistenciaPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Assistencia'),
    },
    {
        path: "/relatorio/auth-pedido/print",
        name: "AuthPedidoRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/AuthPedido'),
    },
    {
        path: "/ferramenta/print/:id",
        props:true,
        name: "FerramentaPrint",
        component: () => import('@pages/Ferramenta/FerramentaPrint'),
    },


    {
        path: "/login",
        name: "Login",
        component: () => import('@pages/Auth/Login'),
        //meta: {guest: true},
    },
]


const router = new VueRouter({
    mode: "history",
    routes
})

function loggedIn() {
    return store.state.isLogged;
}


const DEFAULT_TITLE = 'JCarlos Móveis';
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = 'JCarlos Móveis - ' + to.meta.title || DEFAULT_TITLE;
    });
})

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!loggedIn()) {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (loggedIn()) {
            next({
                path: '/',
                query: {
                    redirect: to.fullPath
                }
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router
